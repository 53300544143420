* {
  padding: 0;
  margin: 0;
}
.App {
  background-color: rgb(236, 236, 236);
  height: 100vh;
}
.header {
  height: 50px;
  background-color: black;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
  margin-bottom: 20px;
}
/* .submit button {
  font-size: 24px;
  color: white;
  background-color: rgb(3, 2, 60);
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
} */

.header ul {
  list-style: none;
  display: flex;
  width: 40%;
  /* border: 2px solid greenyellow; */
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
}

.header ul li a {
  text-decoration: none;
  color: white;
}

.header ul li a:hover {
  cursor: pointer;
  color: greenyellow;
}

.header span {
  color: rgb(3, 194, 41);
}

.header button {
  background-color: rgb(255, 0, 0);
  color: white;
  font-size: 20px;
  font-weight: bold;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
}
.header button:hover {
  cursor: pointer;
  scale: 1.2;
  transition: all 0.5s ease;
}

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.home button {
  font-size: 24px;
  color: white;
  background-color: rgba(3, 2, 60, 0.8);
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
}

.home button:hover {
  cursor: pointer;
  scale: 1.004;
  transition: all 0.2s ease-in-out;
  background-color: rgba(3, 2, 60, 1);
}

.steps .submit button {
  font-size: 24px;
  color: white;
  background-color: rgb(3, 2, 60);
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
}

.home img {
  height: 80px;
}

.steps {
  width: 80%;
  height: 100%;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}
.steps li {
  list-style: none;
  /* border: 1px solid rgb(13, 14, 13); */
  border-radius: 10px;
  background-color: #afc9fd;
  margin: 20px 5px;
  padding: 3px 0px;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.75);
}
.steps li:hover {
  cursor: pointer;
  scale: 1.004;
  transition: all 0.2s ease-in-out;
  background-color: #78a5ff;
  /* background-color: #78a5ff; */
}
.steps .choices {
  margin: 0 auto;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
.algebra {
  height: 400px;
  width: 80%;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.algebra button {
  background-color: #afc9fd;
  color: black;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.75);
  font-size: 24px;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
}

.algebra button:hover {
  cursor: pointer;
  scale: 1.004;
  transition: all 0.2s ease-in-out;

  background-color: #78a5ff;
}
.algebra .choices {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  height: 60%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.multiple-choice {
  width: 80%;
  height: 60%;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}
.multiple-choice button {
  border: none;
  border-radius: 5px;
  margin: 20px 5px;
  padding: 3px 10px;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.75);
  background-color: #afc9fd;
  color: black;
}
.multiple-choice button:hover {
  cursor: pointer;
  scale: 1.004;
  transition: all 0.2s ease-in-out;

  background-color: #78a5ff;
}
.multiple-choice .choices {
  margin: 0 auto;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.multiple-choice button {
  font-size: 14px;
  margin-bottom: 10px;
}

.drawing-activity {
  margin: 0 auto;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  width: 80%;
  font-size: 24px;
  margin-top: 20px;
  padding: 30px;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.75);
}

.fill-in-the-blank {
  width: 80%;
  height: 60%;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}

.fill-in-the-blank .choices {
  margin: 0 auto;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.fill-in-the-blank select {
  background-color: #afc9fd;
  cursor: pointer;
}
.formula {
  width: 80%;
  height: 60%;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}
.formula input {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  font-size: 28px;
  width: 100px;
  border-radius: 5px;
  margin-right: 10px;
  padding-left: 5px;
}
.formula input::placeholder {
  font-size: 14px;
  opacity: 0.5;
}
